(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:viewResource
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <nav-bar></nav-bar>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('resultsPage', resultsPage);

  function resultsPage() {
    return {
      restrict: 'EA',
      templateUrl: 'components/results-page/results-page.tpl.html',
      replace: false,
      controllerAs: 'resultsPage',
      controller: 'ResultsPageCtrl',
      scope: {
        classroomId: '=',
        resourceNode: '=',
        moduleId: '='
      }
    };
  }
}());
